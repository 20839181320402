import React from "react";
import logo from "../assets/avado_logo.svg"
import { NavLink } from "react-router-dom";
import { faLock, faChartBar, faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Comp = ({ location }) => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
<div></div>
    );
};

export default Comp;