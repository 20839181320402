import React from "react";
import { providers, utils } from "ethers";

const Test = () => {
    return (
        <a className="tag is-link" target="_new" href="https://mainnet.tornadocashv2.cloud.ava.do">open relayer</a>
    );
};

const provider = new providers.JsonRpcProvider("https://mainnet.eth.cloud.ava.do");

const RenderDetail = ({ item }) => {

    const [addressInfo, setAddressInfo] = React.useState();

    React.useEffect(() => {
        const getAddressInfo = (address) => {
            Promise.all([
                provider.getTransactionCount(address),
                provider.getBalance(address),
            ])
                .then(([transactionCount, balance]) => {
                    setAddressInfo({
                        transactionCount: transactionCount,
                        balance: parseFloat(utils.formatEther(balance)).toFixed(2)
                    })
                }).catch((e) => {
                });
        }
        if (!item || !item.info || !item.info.rewardAccount) {
            return;
        }
        getAddressInfo(item.info.rewardAccount);
    }, [item]);

    if (!item || !item.info || !item.info.rewardAccount) {
        return (<>{JSON.stringify(item)}</>);
    }
    return (
        <span className="tag is-success">relayer: <a rel="noopener noreferrer" target="_blank" href={`https://etherscan.io/address/${item.info.rewardAccount}`}>{item.info.rewardAccount}</a>
            {addressInfo && (
                <>
                    - {addressInfo.transactionCount} txes - balance: {addressInfo.balance ? addressInfo.balance : 0}
                </>

            )}


        </span>
    );
};

export default { RenderDetail, Test };

