
import { PromiseBlackBox, ReduxBlackBox } from '@oqton/redux-black-box';
import Axios from 'axios';

export const initialState = {
    cloudmembers: []
}

const getCloudMembers = () => {
    return Axios.get()
}

const reducer = (state = initialState, action) => {
    // console.log(`admin reducer ${action.type}`);
    switch (action.type) {
        case "BOOTSTRAP":
            return {
                ...state,
            };

        case "GET_CLOUDMEMBERS":
            return {
                ...state,
                bb_getCloudMembers: new PromiseBlackBox(
                    () =>
                        getCloudMembers()
                            .then(res => ({ type: "GET_CLOUDMEMBERS_SUCCESS", res }))                    // .catch((e) => ({ type: "ADD_INVESTMENT_FAILED", e }))
                            .catch(e => ({ type: "GET_CLOUDMEMBERS_FAILED", e }))                    // .catch((e) => ({ type: "ADD_INVESTMENT_FAILED", e }))
                )
            };

        default:
            return state;
    }
}

export default reducer;
