import React from "react";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import Tree from 'react-d3-tree';
import "./Dashboard.css";
import ReactModal from 'react-modal';
import BlockNumber from '../../../components/BlockNumber';
// import ETHPool from "./ETHPool";
// import IPFSPool from "./IPFSPool";
// import TornadocashPool from "./TornadocashPool";
import GenericPool from "./GenericPool";
import IPFS from "./IPFS";
import ETH from "./ETH";
import Kusama from "./Kusama";
import TornadoCash from "./TornadoCash";
import TornadoCashV2 from "./TornadoCashV2";

const Comp = ({ pools, poolmembers }) => {

    // const [dashBoardData, setDashboardData] = React.useState({});
    // const [showModal, setShowModal] = React.useState(false);

    // React.useEffect(() => {


    //     const activeMembers = poolmembers ? poolmembers.filter((item) => { return !item.idle && item.servicegroup === "eth_rpc_pool" }).map((item, i) => {
    //         return (
    //             <tr key={item.memberid}>
    //                 <td width="5%"><i className="fa fa-bell-o">{item.memberid}</i></td>
    //                 <td>{item.memberdata.publicname || `AVADO box id ${item.memberdata.id}`}</td>
    //                 <td>Blockheight {item.info.blockheight} {item.info.behind > 0 ? (<>{item.info.behind} blocks behind</>) : (<span className="tag is-success">In sync - serving requests</span>)}</td>
    //                 <td>{item.memberdata.age} s ago</td>
    //                 {/* <td className="level-right"><progress className="progress is-primary" value={item.memberdata.age} max="120">{item.memberdata.age}</progress></td> */}
    //             </tr>
    //         )
    //     }) : [];

    //     const idleMembers = poolmembers ? poolmembers.filter((item) => { return item.idle && item.servicegroup === "eth_rpc_pool" }).map((item, i) => {
    //         return (
    //             <tr className="has-text-grey-light" key={item.memberid}>
    //                 <td width="5%"><i className="fa fa-bell-o">{item.memberid}</i></td>
    //                 <td>{item.memberdata.publicname || `AVADO box id ${item.memberdata.id}`}</td>
    //                 <td>Blockheight {item.info.blockheight} <span className="tag is-warning">Idle - removed from pool</span></td>
    //                 <td className="level-right"></td>
    //             </tr>
    //         )
    //     }) : [];

    //     setDashboardData({ activeMembers: activeMembers, totalActive: activeMembers.length, totalIdle: idleMembers.length, idleMembers: idleMembers });


    // }, [poolmembers]);




    // const eth_rpc_pool = () => {

    //     return (

    //         <table className="table is-fullwidth is-striped">
    //             <thead>
    //                 <th>id</th>
    //                 <th>name</th>
    //                 <th>status</th>
    //                 <th>last check</th>
    //             </thead>
    //             <tbody>
    //                 {dashBoardData.activeMembers}
    //                 {dashBoardData.idleMembers}
    //             </tbody>
    //         </table>
    //     )

    // }

    const poolsVis = () => {
        if (!pools || !poolmembers) {
            return (<div>loading</div>)
        }
        return pools.filter((pool) => {
            return (pool.metadata.haslb)
        }).map((pool) => {

            let gp;
            switch (pool.key) {
                case "ipfs_gateway":
                    gp = (<GenericPool givenName={pool.metadata.name} poolName={pool.key} CustomTest={IPFS.Test} CustomDetail={IPFS.RenderDetail} />);
                    break;
                case "eth_rpc_pool":
                    gp = (<GenericPool givenName={pool.metadata.name} poolName={pool.key} CustomTest={ETH.Test} CustomDetail={ETH.RenderDetail} />);
                    break;
                case "tornadocash_relayerv2":
                    gp = (<GenericPool givenName={pool.metadata.name} poolName={pool.key} CustomTest={TornadoCashV2.Test} CustomDetail={TornadoCashV2.RenderDetail} />);
                    break;
                default:
                    gp = (<GenericPool givenName={pool.metadata.name} poolName={pool.key} />);
                    break;
            }
            return (
                <>
                    {/* <div>{pool.key} - {pool.metadata.poolName}</div>
                    <div>{JSON.stringify(pool.metadata)}</div> */}
                    {gp}
                </>
            )
        })
    }


    return (
        <div className="ryo-container">
            <h2>What is AVADO RYO cloud?</h2>
            <div className="content">
                <p>The AVADO RYO cloud pools together AVADO nodes that run residential blockchain nodes to provide high-available endpoints for blockchain applications where the backend nodes are decentralized.</p>
                <p>During the ALPHA phase of the rollout - AVADO will provide a first instance of a load balancer that groups together volounteers that run an AVADO Ethereum or IPFS node on their device at home.</p>
                <p>Later - people will be able to run the load balancer themselves and provision their own clouds to be truly decentralized. If you want to join the pool - go to <a href="https://ava.do" target="_new">https://ava.do</a> to get your AVADO device</p>
            </div>
            {poolsVis()}
            {/* <GenericPool givenName="TornadoCash v2 Relayer pool" poolName="tornadocash_relayerv2" CustomTest={TornadoCashV2.Test} CustomDetail={TornadoCashV2.RenderDetail} />
            <GenericPool givenName="TornadoCash Relayer pool (Kovan testnet)" poolName="tornadocash_relayer_kovan" CustomTest={TornadoCash.Test} CustomDetail={TornadoCash.RenderDetail} />
            <GenericPool givenName="Ethereum Mainnet pool" poolName="eth_rpc_pool" CustomTest={ETH.Test} CustomDetail={ETH.RenderDetail} />
            <GenericPool givenName="IPFS Gateway pool" poolName="ipfs_gateway" CustomTest={IPFS.Test} CustomDetail={IPFS.RenderDetail} />
            <GenericPool givenName="Ethereum Kovan pool" poolName="kovan" CustomTest={ETH.Test} CustomDetail={ETH.RenderDetail} />
            <GenericPool givenName="Polkadot Kusama pool" poolName="kusama_polkadot_pool" CustomDetail={Kusama.RenderDetail} />
            <GenericPool givenName="AVA mainnet pool" poolName="ava_rpc_pool" />
            <GenericPool givenName="AVA testnet pool" poolName="ava_rpc_pool_testnet" />
    */}
        </div>
    )

};

const mapStateToProps = state => {
    // debugger;
    return {
        // ...state.investments,
        // members: state.main.members,
        pools: state.main.pools,
        poolmembers: state.main.poolmembers,
    };
};

const mapDispachToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(Comp);
