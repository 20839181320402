import React from "react";
import ReactModal from 'react-modal';
import BlockNumber from '../../../components/BlockNumber';

const Test = ({endpoint}) => {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <>
            <span className="tag is-link" onClick={() => { setShowModal(true) }}>test</span>
            <ReactModal isOpen={showModal} >
                <BlockNumber endpointURL={endpoint} onClose={() => { setShowModal(false) }} />
            </ReactModal>
        </>
    );
};

const RenderDetail = ({item}) => {
    if (!item || !item.info || !item.info.blockheight){
        return(<>(waiting for test results)</>);
    }
    return (
        <span>Blockheight {item.info.blockheight} {item.info.behind > 0 ? (<>{item.info.behind} blocks behind</>) : (<span className="tag is-success">In sync - serving requests</span>)}</span>
    );
};

export default { RenderDetail, Test };

