import React from "react";
import { connect } from "react-redux";
import "./Dashboard.css";
import { Helmet } from "react-helmet";
import IPFSAdder from "./IPFSAdder";

const Comp = ({ pinsqueue, loadPinQueue }) => {

    React.useEffect(() => {
        console.log("Pinner starts - load pin Queue");
        loadPinQueue();
    }, [loadPinQueue]);

    const pq = (status) => {
        if (!pinsqueue) return null;
        return (
            <table className="table is-fullwidth is-striped">
                <thead>
                    <th>name</th>
                    <th>description</th>
                </thead>
                <tbody>
                    {pinsqueue.filter((item) => { return item.status === status }).map((pin) => {
                        return (
                            <tr className="" key={pin.key}>
                                <td>{pin.key}{pin.key !== pin.hash && (<span> (hash: {pin.hash})</span>)}</td>
                                <td>{pin.description}</td>
                            </tr>

                        )
                    })}
                </tbody>
            </table>
        )



    }

    return (
        <div className="ryo-container">
            <Helmet>
                <link rel="shortcut icon" href="/pinner.ico" />
            </Helmet>

            <IPFSAdder />
            <br/>
            <br/>

            <h2>IPFS suggested content</h2>

            {pq(0)}

            <h2>IPFS approved content</h2>
            {pq(1)}
        </div>
    )
};

const mapStateToProps = state => {
    return {
        pinsqueue: state.main.pinsqueue,
    };
};

const mapDispachToProps = dispatch => {
    return {
        loadPinQueue: () => dispatch({ type: "LOADPINQUEUE" })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(Comp);
