import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./Dashboard.css";

const Comp = ({ rootpath, parentpath, pools, poolmembers }) => {

    // const [dashBoardData, setDashboardData] = React.useState({});
    // const [showModal, setShowModal] = React.useState(false);


    return (
        <div className="container">
            <section>
                <h2 className="is-size-2">ADMIN</h2>
                <Link
                    className=" button is-success is-outlined is-large"
                    to={{
                        pathname: "admin/pins"
                    }}
                >
                    Manage pinset
                    </Link>
            </section>

        </div>


    )



    // return (




    //     <section className="section">
    //         <div className="container has-text-centered">

    //             <h1 className="title">Welcome to RYO cloud</h1>

    //             <div className="treeWrapper">
    //                 <CenteredTree
    //                     orientation="vertical"
    //                     data={treeData} />
    //             </div>
    //         </div>
    //      </section>
    // );
};

const mapStateToProps = state => {
    // debugger;
    return {
        // ...state.investments,
        // members: state.main.members,
        pools: state.main.pools,
        poolmembers: state.main.poolmembers,
    };
};

const mapDispachToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(Comp);
