import React from "react";
import logo from '../assets/ethlogo.svg';
import { providers } from "ethers";
import "./BlockNumber.css";

const Comp = ({ endpointURL, onClose }) => {

    const [blockNumber, setBlocknumber] = React.useState(undefined);
    const [nodeAddressfield, setNodeAddressfield] = React.useState(endpointURL);

    React.useEffect(() => {
        if (nodeAddressfield) {

            const provider = new providers.JsonRpcProvider(nodeAddressfield);

            provider.getBlockNumber().then((blockNumber) => {
                setBlocknumber(blockNumber);
            }).catch((e) => {
                setBlocknumber("waiting...");

            });

            provider.on('block', (blockNumber) => {
                setBlocknumber(blockNumber);
            });

        }
    }, [nodeAddressfield]);

    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content has-text-centered">
                <header className="modal-card-head">
                    <p className="modal-card-title">API check</p>
                    <button className="delete" onClick={onClose} aria-label="close"></button>
                </header>
                <section className="modal-card-body">
                    <p>{nodeAddressfield}</p>
                    <div className="has-image-centered">
                        <figure className="image container is-128x128">
                            <img src={logo} className="image is-128x128" alt="logo" />
                        </figure>
                    </div>
                    <p className="is-size-1">
                        {blockNumber !== undefined ? (<>Blockheight {blockNumber}</>) : (<>...waiting for reply...</>)}
                    </p>
                </section>
            </div>
        </div>

    )
}

export default Comp;
