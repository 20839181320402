import React from "react";
import { connect } from "react-redux";
import { Doughnut } from 'react-chartjs-2';
import Moment from 'react-moment';

const Comp = ({ poolkey, loadPoolStats, poolstats }) => {

    React.useEffect(() => {
        loadPoolStats(poolkey)
    }, [loadPoolStats, poolkey]);

    if (!poolstats) {
        return null;
    }

    let data = [];
    let labels = [];

    const getPublicName = (memberid) => {
        return poolstats.cloudnames[memberid] || memberid;
    }

    Object.keys(poolstats.memberpct).map((key) => {
        labels.push(getPublicName(key));
        data.push(poolstats.memberpct[key]);
    })

    const toHex = (str) => {
        if (!str) { return "#000000" }
        // console.log("toHEX",str);
        var hash = 0;
        if (str.length === 0) return hash;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash;
        }
        var color = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 255;
            color += ('00' + value.toString(16)).substr(-2);
        }
        console.log(str, color);
        return color;
    }

    let chartData = {
        datasets: [{
            data,
            backgroundColor: labels.map((cur, i) => {
                return `${toHex(cur)}`
            })
        }],
        labels: labels
    }
    return (
        <>
            <section class="info-tiles">

                <p class="title is-1">{poolkey} last 24h contributions</p>

                <div class="tile is-ancestor has-text-centered">
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <p class="title">From</p>
                            <p class="subtitle"><Moment>{poolstats.start}</Moment></p>
                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child box">
                            <p class="title">End</p>
                            <p class="subtitle"><Moment>{poolstats.end}</Moment></p>
                        </article>
                    </div>

                </div>
            </section>

            <Doughnut data={chartData} />
        </>
    );
};


const mapStateToProps = state => {
    // debugger;
    return {
        poolstats: state.main.poolstats,
    };
};

const mapDispachToProps = dispatch => {
    return {
        loadPoolStats: (poolkey) => dispatch({ type: "LOADPOOLSTATS", poolkey: poolkey }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(Comp);

