import React from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

const Comp = ({ givenName, pools, poolMembers, poolName, CustomTest, CustomDetail }) => {

    const [dashBoardData, setDashboardData] = React.useState({});
    const [poolMetaData, setPoolMetaData] = React.useState({});
    const [showTable, setShowTable] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    // const [copiedTimeout, setCopiedTimeout] = React.useState();

    React.useEffect(() => {
        setTimeout(() => {
            setCopied(false);
            ReactTooltip.rebuild()
        }, 4000);
        ReactTooltip.rebuild()
    }, [copied]);

    React.useEffect(() => {
        const pool = pools.find((item) => { return item.key === poolName });
        setPoolMetaData(pool.metadata);
        const activeMembers = poolMembers ? poolMembers.filter((item) => { return item.status === 2 && item.servicegroup === poolName }).map((item, i) => {
            return (
                <tr key={item.memberid}>
                    <td width="5%"><i className="fa fa-bell-o">{item.memberid}</i></td>
                    <td>{item.memberdata.publicname || `AVADO box id ${item.memberdata.id}`}</td>
                    <td>{CustomDetail ? (<CustomDetail item={item} />) : (<span className="tag is-success">serving requests</span>)}</td>
                    <td>{item.memberdata.age} s ago</td>
                </tr>
            )
        }) : [];

        const idleMembers = poolMembers ? poolMembers.filter((item) => { return item.status !== 2 && item.servicegroup === poolName }).map((item, i) => {
            return (
                <tr className="has-text-grey-light" key={item.memberid}>
                    <td width="5%"><i className="fa fa-bell-o">{item.memberid}</i></td>
                    <td>{item.memberdata.publicname || `AVADO box id ${item.memberdata.id}`}</td>
                    <td><span className="tag is-warning">Inactive - removed from pool</span></td>
                    <td className="level-right"></td>
                </tr>
            )
        }) : [];

        setDashboardData({ activeMembers: activeMembers, totalActive: activeMembers.length, totalIdle: idleMembers.length, idleMembers: idleMembers });
    }, [poolMembers, poolName, pools, CustomDetail]);


    const pooltable = () => {
        return (
            <table className="table is-fullwidth is-striped">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>name</th>
                        <th>status</th>
                        <th>last check</th>
                    </tr>
                </thead>
                <tbody>
                    {dashBoardData.activeMembers}
                    {dashBoardData.idleMembers}
                </tbody>
            </table>
        )
    }

    if (!poolMetaData || !dashBoardData || !dashBoardData.totalActive) {
        return (
            <section>
                <div className="endpoint-box">
                    <h1 className="title">{givenName}</h1>
                    <p>...Loading Pool details</p>
                </div>
            </section>

        )
    }

    if (dashBoardData.totalActive === 0) { return null; }

    const urls = () => {
        if (poolMetaData.poolURLS) {
            return poolMetaData.poolURLS.map((entry) => {
                return (<>
                    <p className="subtitle">{entry.name}</p>
                    <CopyToClipboard key={entry.url} text={entry.url}
                        onCopy={() => setCopied(true)}>
                        <p data-tip="click to copy" className="title">{entry.url}</p>
                    </CopyToClipboard>
                </>
                );
            })
        } else {
            return (
                <>
                    <CopyToClipboard text={poolMetaData.poolURL}
                        onCopy={() => setCopied(true)}>
                        <p data-tip="click to copy" className="title">{poolMetaData.poolURL}</p>
                    </CopyToClipboard>
                </>
            )
        }
    }


    return (
        <>
            <ReactTooltip />
            <section>
                <div className="endpoint-box">
                    <div className="tile is-primary is-ancestor has-text-centered">
                        <div className="tile is-parent">
                            <div className="tile is-child">
                                <p className="subtitle">{poolMetaData.poolName}</p>
                                {urls()}
                            </div>
                        </div>
                    </div>
                    <div className="tile is-primary is-ancestor has-text-centered">
                        <div className="tile is-parent">
                            <div className="tile is-child">
                                <p className="subtitle">Active nodes</p>
                                <p className="title">{dashBoardData.totalActive}</p>
                                <p onClick={() => { setShowTable(!showTable) }} >{showTable ? (<>hide nodes</>) : (<>show nodes</>)}</p>
                            </div>
                        </div>
                        {CustomTest && (
                            <div className="tile is-parent">
                                <div className="tile is-child">
                                    <p className="subtitle">Test me!</p>
                                    <p className="title"><CustomTest endpoint={poolMetaData && poolMetaData.poolURL} /></p>
                                </div>
                            </div>
                        )}
                    </div>
                    {showTable && (
                        <>
                            {pooltable()}
                        </>
                    )}
                </div>
            </section>

            {/* <section className="info-tiles">
                <div className="tile  is-primary  is-ancestor has-text-centered">
                    <div className="tile is-fullwidth is-parent">
                        <article className="tile is-child box">
                            {pooltable()}
                        </article>
                    </div>
                </div>
            </section> */}
        </>
    )
};

const mapStateToProps = state => {
    return {
        pools: state.main.pools,
        poolMembers: state.main.poolmembers,
    };
};

const mapDispachToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(Comp);
