import React from "react";
import { connect } from "react-redux";
import "./Dashboard.css";
// import ENS from 'ethereum-ens';
import isIPFS from 'is-ipfs';
import contentHash from 'content-hash';
import { Formik } from "formik";
// import ENS from 'ethereum-ens';
// import Web3 from 'web3';
import { getContent } from "./ens";

// var provider = new Web3.providers.HttpProvider("https://mainnet.eth.cloud.ava.do"); // /
// var ens = new ENS(provider);

const Comp = ({ submitHash }) => {
    const [showPinResult, setShowPinResult] = React.useState(false);
    const [resolving, setResolving] = React.useState(false);

    const parseIPFSHash = async (input) => {
        if (isIPFS.multihash(input)) {
            // this is an IPFS hash
            return input;
        }
        if (/^(\w+\.)*\w+(\.eth)$/.test(input)) {
            const content = await getContent(input);
            if (content === "0x404") {
                return null;
            } else if (content === "0x") {
                return null;
            } else {
                if (content.startsWith("/ipfs/")) {
                    const hash = content.substring(6);
                    if (isIPFS.multihash(hash)) {
                        return hash;
                    }
                    return null;
                }
            }
        }
    }

    const initialFormValues = {
        key: "",
        hash: "",
        isens: false,
        description: ""
    }

    const resolveHash = async (val, setFieldValue) => {
        console.log("Try finding a hash");
        if (val === "") {
            return setFieldValue("isens", false);
        }
        setResolving(true);
        const hash = await parseIPFSHash(val);
        setFieldValue("hash", hash);
        setFieldValue("isens", hash !== null && hash !== val);
        setResolving(false);
    }

    return (
        <div>
            <h2 className="is-size-3">Suggest an IPFS hash or ENS name</h2>
            <p>Fill out the form here if there are hashes you want to add. In a second phase we will allow AVADO pool members to curate hashes, for now the AVADO team takes care of the curation of this list.</p>
            <Formik
                initialValues={
                    initialFormValues
                }
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    submitHash(values, (success) => {
                        console.log("Setting submit to false")
                        setSubmitting(false);
                        if (success) {
                            setShowPinResult("thanks for submitting your hash");
                            resetForm();
                        }
                    });
                }}
                validate={async (values) => {
                    let errors = {};
                    if (values.key === "") {
                        errors["key"] = "required";
                    } else {
                        // const ipfsh = await parseIPFSHash(values.key);
                        // console.log("ipsf hash = ", ipfsh);
                    }
                    if (values.description === "") {
                        errors["description"] = "required";
                    }
                    if (!isIPFS.multihash(values.hash)) {
                        errors["key"] = "hash not found or ENS name does not resolve to a hash";
                    }
                    return errors;
                }}

            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        resetForm,
                        submitForm,
                        setFieldValue
                    } = props;
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="setting">
                                    <h3 className="is-size-5">IPFS hash or ENS name</h3>
                                    <div className="field">
                                        <p className={`control ${resolving ? " is-loading" : ""}`}>

                                            <input
                                                id="key"
                                                placeholder="IPFS hash or ENS Name"
                                                type="text"
                                                value={values.key}
                                                onChange={(e) => { handleChange(e); resolveHash(e.target.value, setFieldValue); }}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.key && touched.key
                                                        ? "input is-danger"
                                                        : "input"
                                                }
                                            />
                                        </p>
                                        {errors.key && touched.key && (
                                            <p className="help is-danger">{errors.key}</p>
                                        )}
                                        <p>Pro tip: If you use an ENS name - the hash will be updated whenever the content of the ENS name changes</p>
                                        {values.isens && values.hash && (
                                            <p>hash: {values.hash}</p>
                                        )}
                                    </div>
                                </div>

                                <div className="setting">
                                    <h3 className="is-size-5">Short description</h3>
                                    {/* <p>Note: This will be used to show your node in statistics - so don't use personal identifiable information here</p> */}
                                    <div className="field">
                                        <p className="control">
                                            <input
                                                id="description"
                                                placeholder="description"
                                                type="text"
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.description && touched.description
                                                        ? "input is-danger"
                                                        : "input"
                                                }
                                            />
                                        </p>
                                        {errors.description && touched.description && (
                                            <p className="help is-danger">{errors.description}</p>
                                        )}
                                    </div>
                                </div>
                                <br />
                                <div className="field is-grouped buttons">
                                    <p className="control">
                                        <div disabled={isSubmitting} onClick={() => { submitForm(); }} className="button is-medium is-success">Submit</div>
                                    </p>
                                </div>
                            </form>
                            <div>{showPinResult}</div>
                        </>
                    );
                }}
            </Formik>
        </div>
    )
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {
        submitHash: (data, callback) => dispatch({ type: "SUBMITHASH", data: data, callback: callback }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(Comp);
