import React, { useEffect } from "react";
// import "./sass/relai.sass";
import "./sass/avadoryo.sass";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import pages from "./pages";
import CatchAll from "./pages/CatchAll";
import Navigation from "./components/Navigation";
import Websocket from 'react-websocket';
import config from "./config";

function App({ bootstrap, connect, disconnect, onMessage }) {

    useEffect(() => {
        bootstrap();

    }, [bootstrap]);

    return (
        <div className="App">
            <Websocket url={config.api.URL}
                onOpen={connect}
                onClose={disconnect}
                onMessage={onMessage} />
            <BrowserRouter>
                <Switch>
                    {Object.values(pages).map(({ RootComponent, parentPath, rootPath }) => (
                        <Route
                            key={rootPath}
                            path={rootPath}
                            // exact={rootPath === "/"}
                            render={props => (
                                <section>
                                    <Navigation />
                                    <RootComponent parentpath={parentPath} rootpath={rootPath} {...props} />
                                </section>
                            )}
                        />
                    ))}
                    {/* <Route component={CatchAll} /> */}
                </Switch>
            </BrowserRouter>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        ...state.wallets
    };
};

const mapDispachToProps = dispatch => {
    return {
        bootstrap: () => dispatch({ type: "BOOTSTRAP" }),
        connect: () => dispatch({ type: "CONNECTED" }),
        disconnect: () => dispatch({ type: "DISCONNECTED" }),
        onMessage: (message) => dispatch({ type: "MESSAGE", message: message }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(App);
