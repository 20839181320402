import React from "react";
import "./Dashboard.css";
import { connect } from "react-redux";

import { withCookies, Cookies } from "react-cookie";
import admin from "..";

const Comp = ({ cookies, onIsLoggedin, showlogout,setAdminToken }) => {

    const inputVal = React.createRef();

    React.useEffect(() => {
        const ac = cookies.get("admintoken");
        setAdminToken(ac);
        console.log(`Setting loggedin to ${ac != null} ac=${ac}`);
        onIsLoggedin && onIsLoggedin(ac != null);
    }, [cookies, setAdminToken, onIsLoggedin]);

    const setCookie = (val) => {
        console.log("setcookie", val);
        if (val === null) {
            console.log("Logging out..");
            onIsLoggedin && onIsLoggedin(false);
            setAdminToken(null);
            return cookies.remove("admintoken", { path: "/" });
        }
        setAdminToken(val);
        cookies.set("admintoken", val, { path: "/" });
        onIsLoggedin && onIsLoggedin(true);
    }

    const cookieVal = cookies.get("admintoken");
    if (cookieVal) {
        if (!showlogout) {
            return null;
        }
        return (<span onClick={() => { setCookie(null) }}>logout</span>)
    }

    return (
        <div className="container">
            <section>
                <h2 className="is-size-2">set admin token</h2>
            </section>
            <input type="text" className="input" ref={inputVal}></input>
            <button className="button" onClick={(e) => { setCookie(inputVal.current.value) }}>Login</button>
        </div>
    )


};

const mapStateToProps = state => {
    return {
        // pinsqueue: state.main.pinsqueue,
    };
};

const mapDispachToProps = dispatch => {
    return {
        setAdminToken: (admintoken) => dispatch({ type: "SETADMINTOKEN", admintoken: admintoken }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(withCookies(Comp));

// export default withCookies(Comp);
