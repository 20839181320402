import React from "react";

const Test = () => {
    return (
        <a className="tag is-link" target="_new" href="https://ipfs.cloud.ava.do/ipfs/Qmc5gCcjYypU7y28oCALwfSvxCBskLuPKWpK4qpterKC7z">test</a>
    );
};

const RenderDetail = (item) => {
    return (
        <span className="tag is-success">data consisent - serving requests</span>
    );
};

export default { RenderDetail, Test };

